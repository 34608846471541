div.config-view-date-list {
  text-align: center;
  vertical-align: middle;
}

div.config-view-date-add {
  text-align: center;
  vertical-align: middle;
  margin-bottom: 10px;
}

span.date-delete-button {
  margin-top: 25px;
}
