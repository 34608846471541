.welcome-card-item {
  border: "4px solid black";
  display: "inline-flex";
  border-radius: 30px;
  background-color: #cc9966;
}

.welcome-card-bottom-buffer {
  margin-bottom: 20px;
}
