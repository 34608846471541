table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

table {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 30px;
  width: 90%;
}

th,
td {
  text-align: left;
}

tr:nth-child(even) {
  background-color: #e75757;
}

th.result {
  text-align: center;
  width: 80px;
}

th.jump-ht,
td.jump-ht {
  width: 80px;
  text-align: center;
}

th.dog-name {
  width: 100px;
}

th.breed {
  width: 160px;
}
