.entry-form-payment-dog-header {
  font-size: larger;
  font-weight: bold;
}

.entry-form-payment-bold {
  font-weight: bold;
}

.entry-form-payment-clear-msg {
  margin-right: 10px;
}