.app-secretary-events-card {
  margin-top: 10px;
  border: 2px solid black;
  border-radius: 25px;
}

.app-secretary-events-header {
  background-color: #cc9966;
  position: sticky;
}

.app-secretary-events-header-text {
  font-size: 2em;
  text-align: center;
  margin-top: 22px;
  margin-bottom: 10px;
}

.app-secretary-events-card-line-bold {
  font-weight: 800;
}

.app-secretary-events-card-line {
  font-size: 1.2em;
}

.app-secretary-events-card-line-event-type {
  font-weight: 800;
  color: #cc9966;
  margin-bottom: 10px;
}

.app-secretary-events-margin-botttom-10 {
  margin-bottom: 10px;
}

.app-secretary-events-card-first-line {
  margin-top: 10px;
}
