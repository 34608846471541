.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.margin-right-10 {
  margin-right: 10px;
}

.owner-info {
  margin-top: 10px;
  margin-bottom: 10px;
}

.session-title-readonly {
  margin-top: 20px;
  font-weight: bold;
  text-decoration: underline;
}

.session-slot-readonly > span {
  margin-right: 5px;
}

.missing-data {
  color: red;
  margin-top: 30px;
  margin-bottom: 20px;
}

.info-data {
  color: blue;
  margin-top: 30px;
  margin-bottom: 20px;
}

.app-loading {
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 20px;
}
.app-session-selection {
  margin-top: 10px;
  margin-bottom: 10px;
}

.app-event-inactive {
  margin-top: 20px;
  font-weight: bold;
  color: red;
}

span#app-email-confirm-msg-prefix {
  color: red;
}

div#app-notices-header {
  font-weight: bold;
}

div.app-notice {
  color: red;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div#admin-view-height-header-12, div#admin-view-height-header-20 {
  page-break-before: always;
}

@media print {
  .noprint {
    display: none;
  }

  .admin-view-session-wrapper {
    page-break-inside: avoid;
  }

  h2:not(:first-of-type) {
    page-break-before: always;
  }

  h2 :first-of-type {
    page-break-before: avoid;
  }

  div {
    page-break-inside: avoid;
  }

  input {
    background-image: none !important;
  }

  div#app-entry-start-available-msg {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
