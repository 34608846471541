.config-view-roster-field-dog-name,
.config-view-roster-field-email {
  width: 100%;
}

.config-view-roster-error-msg {
  color: red;
}

#configView-roster-add-participant {
  margin-bottom: 10px;
}
