div.config-view-entry-events-field-container, span.config-view-entry-events-delete-button {
  text-align: center;
  vertical-align: middle;
}

div.config-view-entry-events-add {
  text-align: center;
  vertical-align: middle;
  margin-bottom: 10px;
}
