div.config-view-file-list {
  text-align: center;
  vertical-align: middle;
}

div.config-view-file-add {
  text-align: center;
  vertical-align: middle;
  margin-bottom: 10px;
}

.config-view-file-field-label {
  width: 15%;
}

.config-view-file-field-url {
  width: 30%;
}

.config-view-file-input {
  margin-left: 10px;
  width: 350px;
}
