.welcome-view-container {
  margin-left: 40px !important;
}

.welcome-view-service-overview {
  margin-top: 10px;
  margin-bottom: 10px;
}

.welcome-view-general-overview-card {
  border: "4px solid black";
  display: "inline-flex";
  border-radius: 30px;
  background-color: #cc9966;
}

.welcome-view-bottom {
  margin-bottom: 10px;
}
