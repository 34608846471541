div.class-view-student-name {
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 8px;
}

div.class-view-date {
  margin-bottom: 10px;
}

button#class-view-present-absent-button {
  margin-left: 10px;
}
div.class-view {
  margin-top: 20px;
}
