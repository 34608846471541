.entry-form-print-msg {
  font-weight: bold;
}

.entry-form-print-sec-contact {
  font-weight: bold;
}

h1 {
  text-align: center;
}

div.entry-form-print-header {
  width: 100%;
}

div.table {
  display: block;
  width: 100%;
}

div.row {
  display: block;
  width: 100%;
  border-top: solid 1px black;
  border-bottom: solid 1px black;  
}

div.cell { 
  display: inline-block;
  text-align: start;
  border-left: solid 1px black;
  border-right: solid 1px black;
  padding-left: 10px;
}

div.entry-form-print-breeder,
div.entry-form-print-sire,
div.entry-form-print-dam,
div.entry-form-print-owner,
div.entry-form-print-address,
div.entry-form-print-phone,
div.entry-form-print-email,
div.entry-form-print-handler {
  width: 100%;
}
div.entry-form-print-breed,
div.entry-form-print-akc-number,
div.entry-form-print-city {
  width: 55%;
}

div.entry-form-print-variety,
div.entry-form-print-sex,
div.entry-form-print-date-of-birth,
div.entry-form-print-place-of-birth,
div.entry-form-print-state,
div.entry-form-print-zip {
  width: 20%;
}

div.entry-form-print-brace,
div.entry-form-print-team {
  width: 45%;
}

div.entry-form-print-jr-handler,
div.entry-form-print-dog-name {
  width: 71%;
}

div.entry-form-print-jr-number,
div.entry-form-print-call-name {
  width: 25%;
}
div#entry-form-print-agreement-header {
  font-size: xx-large;
  width: 100%;
  text-align: center;
}
div#entry-form-print-agreement-text {
  font-size: small;
}

button#entry-form-print-print-button {
  margin-bottom: 10px;;
}

.entry-form-print-signature {
  margin-top: 20px;
}

.entry-form-print-signature-line {
  text-decoration: underline;
}
