.entry-form-data-warning-icon {
  vertical-align: bottom;
  color: orange;
}

.entry-form-data-error-icon {
  vertical-align: bottom;
  color: red;
}

.entry-form-data-class {
  margin-bottom: 5px;
}

.entry-form-data-class-field {
  margin-left: 10px;
}

div.entry-form-data-class-header {
  font-size: large;
}

div#entry-form-data-errors {
  color: red;
  font-weight: bold;
  font-size: large;
}

div.entry-form-data-jump-ht {
  margin-left: 10px;
  width: 100px;
}

div.entry-form-data-team-name {
  margin-left: 10px;
  width: 200px;
}

button.entry-form-cancel-button {
  margin-left: 20px;
  margin-right: 20px;
}
