.entry-form-summary-cart-status-msg {
  font-style: italic;
}

.entry-form-summary-call-name {
  font-weight: bold;
  font-size: large;
}

.entry-form-summary-event-name {
  font-weight: 600;
}

.entry-form-summary-button {
  margin-left: 10px;
}