.login-message-container {
  width: 100%;
  background-color: #cc9966;
  margin-top: 20px;
  margin-bottom: 10px;
}
.login-message-grid-item {
  margin-bottom: 10px;
}

.login-message-container {
  width: 100%;
  background-color: #cc9966;
  margin-top: 20px;
  margin-bottom: 10px;
}

.login-message {
  margin-top: 10px;
  font-size: 1.1em;
}