.app-secretary-results-header {
  background-color: #cc9966;
  position: sticky;
}

.app-secretary-results-header-text {
  font-size: 2em;
  text-align: center;
}

.app-secretary-results-card {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid black;
  border-radius: 25px;
}

#secretary-results-club-name {
  font-size: 1.2em;
  font-weight: 800;
  margin-top: 10px;
  margin-bottom:10px;
}