.entry-form-done-msg {
  font-weight: bold;
}

.entry-form-done-sec-contact {
  font-weight: bold;
}

h1 {
  text-align: center;
}

div.entry-form-done-header {
  width: 100%;
}

div.table {
  display: block;
  width: 100%;
}

div.row {
  display: block;
  width: 100%;
  border-top: solid 1px black;
  border-bottom: solid 1px black;  
}

div.cell { 
  display: inline-block;
  text-align: start;
  border-left: solid 1px black;
  border-right: solid 1px black;
  padding-left: 10px;
}

div#entry-form-done-agreement-header {
  font-size: xx-large;
  width: 100%;
  text-align: center;
}
div#entry-form-done-agreement-text {
  font-size: small;
}

button#entry-form-done-print-button {
  margin-bottom: 10px;;
}

.entry-form-done-call-name {
  float: right;
}