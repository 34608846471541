div.user-profile-email,
div.user-profile-street {
  margin-left: 10px;
  width: 400px;
}

div.user-profile-first-name,
div.user-profile-last-name,
div.user-profile-city,
div.user-profile-phone {
  margin-left: 10px;
  width: 200px;
}

div.user-profile-state,
div.user-profile-zip {
  margin-left: 10px;
  width: 145px;
}

.user-profile-view-margin-right-10 {
  margin-right: 10px;
}

.user-profile-view-warning-icon {
  vertical-align: bottom;
  color: orange;
}

.user-profile-view-error-icon {
  vertical-align: bottom;
  color: red;
}

span#user-profile-view-event-admin {
  margin-top: 10px;
}
